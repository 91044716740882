
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatDate } from "@/utils/formatters";
import { bus } from "@/main";

@Component
export default class Documentcard extends Vue {
    @Prop() document!: Document;

    @Prop({ default: true }) canEdit!: boolean;
    @Prop({ default: true }) canPreview!: boolean;

    bus = bus;

    formatDate = formatDate;

    get isImage() {
        return this.document.media.length && this.document.media[0].mime_type.includes("image");
    }
}
